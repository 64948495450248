import {
  faAmazon,
  faApple,
  faFacebookF,
  faInstagram,
  faSoundcloud,
  faSpotify,
  faTiktok,
  faTwitter,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faX } from "@fortawesome/free-solid-svg-icons";

interface SocialIconsTypes {
  icon?: any;
  link: string;
  img?: string;
  isPandora?: boolean;
}

export const data: SocialIconsTypes[] = [
  // {
  //   icon: faAmazon,
  //   link: "#",
  // },
  // {
  //   icon: faXTwitter,
  //   link: "#",
  // },
  {
    icon: faInstagram,
    link: "https://www.instagram.com/stadiumphx/?hl=en",
  },
  // {
  //   icon: faFacebookF,
  //   link: "#",
  // },
];
