import { ShowcaseSliderDataTypes } from "../../components/ShowcaseSlider/types";
import { RoutePattern } from "../../routes/RoutePattern";
import Showcase from "./Showcase";
import IMG_1 from "../../assets/images/slider/1.png";
import IMG_2 from "../../assets/images/slider/2.png";
import IMG_3 from "../../assets/images/slider/3.png";
import IMG_6 from "../../assets/images/slider/4.png";
import IMG_4 from "../../assets/images/4.jpg";
import IMG_5 from "../../assets/images/5.jpg";
import PATTERN_IMG from "../../assets/images/pattern.png";
import ShowcaseSlider from "../../components/ShowcaseSlider/ShowcaseSlider";
import Newsletter from "../../components/Newsletter/Newsletter";

const data: ShowcaseSliderDataTypes[] = [
  {
    img: IMG_1,
  },
  {
    img: IMG_2,
  },
  {
    img: IMG_3,
  },
  {
    img: IMG_6,
  },
];

const Home = () => {
  return (
    <div
      id={RoutePattern.HOME}
      className="relative flex flex-col h-full md:min-h-screen"
    >
      <ShowcaseSlider data={data} arrows={false} title="" />
      {/* <div className="py-[10vh] px-5 md:px-[10vw] text-dark text-center relative">
        <div className="bg-white w-full h-full absolute top-0 left-0 z-0">
          <img
            className="w-full h-full opacity-20 object-cover"
            src={PATTERN_IMG}
            alt="pattern"
          />
        </div>
        <div className="text-center z-10 relative">
          <h2 className="heading-2">Lorem IPSUM</h2>
          <p className="mt-[5vh]">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolorum,
            aspernatur accusamus. Quibusdam labore ipsa eum facilis mollitia
            omnis numquam quae officiis blanditiis maxime exercitationem,
            reiciendis saepe quam vero, velit natus et expedita dolorum
            corrupti! Voluptatibus non nulla reprehenderit amet hic obcaecati
            dicta, sit enim mollitia quos nemo quis? Quia, eum blanditiis
            numquam perspiciatis architecto officia natus reiciendis quibusdam
            ea rem quod ipsam, mollitia perferendis, assumenda hic
            necessitatibus autem itaque maiores veniam commodi cupiditate! Sint
            quae ab quidem natus voluptas fugiat cumque aliquam eos, tempora
            perferendis qui libero recusandae illo soluta non incidunt
            necessitatibus consequatur sunt sapiente minus ea temporibus unde.
          </p>
        </div>
      </div> */}
      <div className="flex flex-col-reverse py-[7vh] px-5 lg:flex-row md:px-[5vw] md:py-[5vw] ">
        <div className="w-full">
          <img src={IMG_4} className="w-full h-full" />
        </div>
        <div className="w-full px-[2vw] text-center m-auto pb-[4vh] h-full lg:pb-0 lg:w-3/4 ">
          <div className="outer-border-1 mx-auto flex">
            <div className="outer-border-2 flex justify-center items-center flex-col w-full px-[2vw]">
              <h3 className="heading-3">About us</h3>
              <p>
                Stadium Sports Bar is the ultimate sports destination located in
                the heart of downtown Phoenix. With a prime location,
                state-of-the-art facility, and a lively atmosphere, it's the
                go-to spot for sports enthusiasts. Featuring multiple large
                screens, high-definition televisions, and an immersive
                audiovisual experience, Stadium Sports Bar ensures that every
                guest has a front-row seat to all the action.
              </p>
              <br />
              {/* <button className="btn mt-[5vh]">view more</button> */}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col pb-[7vh] px-5 lg:flex-row md:px-[5vw] md:pb-[5vw]">
        <div className="w-full px-[2vw] text-center m-auto pb-[4vh] h-full lg:pb-0 lg:w-3/4 ">
          <div className="outer-border-1 mx-auto flex">
            <div className="outer-border-2 flex justify-center items-center flex-col w-full px-[2vw]">
              {/* <h3 className="heading-3">lorem ipsum</h3> */}
              <p>
                Whether you're a die-hard fan or simply looking for a fun night
                out, Stadium Sports Bar offers a friendly staff, a wide
                selection of beverages, and a diverse menu of delicious dishes,
                making it the perfect place to gather with friends and enjoy the
                excitement of sports in the downtown Phoenix CityScape area.
              </p>
              <br />
              {/* <button className="btn mt-[5vh]">view more</button> */}
            </div>
          </div>
        </div>
        <div className="w-full">
          <img src={IMG_5} className="w-full h-full" />
        </div>
      </div>
    </div>
  );
};

export default Home;
