import { ShowcaseSliderDataTypes } from "../../components/ShowcaseSlider/types";
import { RoutePattern } from "../../routes/RoutePattern";
import IMG_1 from "../../assets/images/1.jpg";
import IMG_2 from "../../assets/images/2.jpg";
import IMG_3 from "../../assets/images/3.jpg";
// import IMG_4 from "../../assets/images/4.jpg";
import IMG_4 from "../../assets/images/4.jpg";
import PATTERN_IMG from "../../assets/images/pattern.png";
import ShowcaseSlider from "../../components/ShowcaseSlider/ShowcaseSlider";
import Newsletter from "../../components/Newsletter/Newsletter";

export const data: ShowcaseSliderDataTypes[] = [
  {
    img: IMG_1,
  },
  {
    img: IMG_2,
  },
  {
    img: IMG_3,
  },
];

export default function Events() {
  return (
    <div
      id={RoutePattern.EVENTS}
      className="relative flex flex-col justify-center items-center h-full min-h-screen"
    >
      {/* <ShowcaseSlider data={data} title="event calendar" /> */}
      <div className="text-center py-[7vh] px-5 lg:flex-row md:px-[5vw] md:py-[5vw]">
        <h3 className="heading-2 text-center">Coming Soon...</h3>
      </div>
      {/* <div className="flex flex-col py-[7vh] px-5 lg:flex-row md:px-[5vw] md:py-[5vw]">
        <div className="w-full px-[2vw] text-center m-auto h-full pb-[4vh] lg:pb-0 lg:w-3/4 ">
          <div className="outer-border-1 mx-auto flex">
            <div className="outer-border-2 flex justify-center items-center flex-col w-full">
              <h3 className="heading-3">happy hour</h3>
              <p>
                Monday - Thursday:
                <br />
                4:00 PM - 7:00 PM
              </p>
              <br />
              <h3 className="heading-3">
                rooftop <br /> remix
              </h3>
              <p>Friday - Saturday Night</p>
              <br />
              <h3 className="heading-3">
                gameday
                <br /> brunch
              </h3>
              <p>
                Saturday - Sunday:
                <br />
                11:00 AM - 4:00 PM
              </p>
            </div>
          </div>
        </div>
        <div className="w-full">
          <img src={IMG_4} className="w-full h-full" />
        </div>
      </div> */}
    </div>
  );
}
