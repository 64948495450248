import { AnimatePresence } from "framer-motion";
import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import {
  Events,
  Home,
  HoursAndLocation,
  Menu,
  PageNotFound,
  PrivateEvent,
  SportsCalendar,
  Press,
  EmailSignup,
  About,
} from "../app/index";
import useRouteService from "../hooks/useRouteService";
import { RoutePattern } from "./RoutePattern";
import useScrollTop from "../hooks/useScrollTop";

export default function AppRoutes() {
  const location = useLocation();
  const { setCurrentRoute } = useRouteService();

  useEffect(() => {
    setCurrentRoute(window.location.pathname);
  }, [location.pathname]);

  useScrollTop();

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path={"/" + RoutePattern.ABOUT} element={<About />} />
        <Route path={"/" + RoutePattern.MENU} element={<Menu />} />
        <Route
          path={"/" + RoutePattern.HOURS_AND_LOCATION}
          element={<HoursAndLocation />}
        />
        <Route path={"/" + RoutePattern.EVENTS} element={<Events />} />
        <Route path={"/" + RoutePattern.SPORTS} element={<SportsCalendar />} />
        <Route
          path={"/" + RoutePattern.PRIVATE_EVENT}
          element={<PrivateEvent />}
        />
        <Route path={"/" + RoutePattern.PRESS} element={<Press />} />
        <Route
          path={"/" + RoutePattern.EMAIL_SIGNUP}
          element={<EmailSignup />}
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </AnimatePresence>
  );
}
