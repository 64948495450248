import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  popupActive: false,
  presavePopup: true, // initial
  requiredPopup: true,
  navbarHeight: "",
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setPopupActive: (state, { payload }: PayloadAction<boolean>) => {
      state.popupActive = payload;
    },
    setPresavePopup: (state, { payload }: PayloadAction<boolean>) => {
      state.presavePopup = payload;
    },
    setRequiredPopup: (state, { payload }: PayloadAction<boolean>) => {
      state.requiredPopup = payload;
    },
    setNavbarHeight: (state, { payload }: PayloadAction<string>) => {
      state.navbarHeight = payload;
    },
  },
});

export const {
  setPopupActive,
  setPresavePopup,
  setRequiredPopup,
  setNavbarHeight,
} = globalSlice.actions;
