export type MenuContent = {
  title: string;
  description?: string;
  price?: string;
  spicyLevel?: number;
};

export interface MenuData {
  headingTitle?: string;
  outerBorder?: boolean;
  inline?: boolean;
  content: MenuContent[];
}

export const menuData: MenuData[] = [
  {
    headingTitle: "appetizers",
    outerBorder: true,
    content: [
      {
        title: "Lobster Bites",
        description:
          "Crispy battered lobster meat tossed in old bay seasoning with French fries and our signature Stadium sauce.",
        price: "$18",
      },
      {
        title: "philly cheesesteak egg rolls",
        description:
          "Everything you love in a philly wrapped in a crispy egg roll.",
        price: "$14",
      },
      {
        title: "buffalo chicken egg rolls",
        description:
          "Our famous buffalo chicken dip wrapped in a crispy egg roll",
        price: "$14",
      },
      {
        title: "onion rings",
        description: "Beer battered onions with your choice of dipping sauce.",
        price: "$6",
      },
      {
        title: "pretzel bites",
        description:
          "Fresh baked salted pretzels with jalapenous cheese sauce.",
        price: "$12",
      },
      {
        title: "stadium popcorn",
        description:
          "Fresh buttered popcorn sprinkled with any one of our specialty spices.",
        price: "$7",
      },
    ],
  },
  {
    headingTitle: "hot dogs",
    content: [
      {
        title: "arizona dog",
        description:
          "Hot dog, with pinto beans, tomatoes, green salsa, jalopeno, mustard, and mayonnaise, and with avocado and cotija cheese on top.",
        price: "$12",
      },
      {
        title: "seattle dog",
        description: "Jalopeno, cream cheese, grilled onions, brown mustard.",
        price: "$10",
      },
      {
        title: "chicago dog",
        description:
          "Onion, pickled relish, sport pepper, tomato, and mustard.",
        price: "$10",
      },
      {
        title: "la dog",
        description:
          "Bacon wrapped hotdog, onions, bell peppers, mayo, ketchup, mustard.",
        price: "$12",
      },
    ],
  },
  {
    headingTitle: "big plates",
    content: [
      {
        title: "grilled ribeye",
        description: "Boz grilled ribeye served with choice of side.",
        price: "$42",
      },
      {
        title: "lobster roll and fries",
        description:
          "Main lobster meat, toasted New England roll, truffle garlic butter.",
        price: "$22",
      },
      {
        title: "nashville hot chicken sandwich",
        description:
          "Spicy battered chicken breast and coleslaw, stadium sauce, and fries.",
        price: "$18",
      },
      {
        title: "lobster tail plate",
        description: "8oz lobster served with your choice of side.",
        price: "$35",
      },
      {
        title: "steak fries",
        description:
          "8oz skirt steak tooped with chimichurri and garlic parmasen fries.",
        price: "$25",
      },
      {
        title: "chicken fingers & fries",
        description: "Homemade battered chicken strips and french fries.",
        price: "$18",
      },
      {
        title: "philly cheese steak (beef or chicken)",
        description:
          "Sliced ribeye, mushrooms, onions, bell peppers, mayo, and provolone cheese.",
        price: "$35",
      },
    ],
  },
  {
    headingTitle: "wings",
    outerBorder: true,
    content: [
      {
        title: "doubled fried naked wings",
        description: "With your choice of sauce or dry rubs",
        price: "$17",
      },

      {
        title: "buffao",
        spicyLevel: 5,
      },
      {
        title: "honey hot",
        spicyLevel: 3,
      },
      {
        title: "jerk dry rub",
        spicyLevel: 2,
      },

      {
        title: "cajun rub",
        spicyLevel: 1,
      },
      {
        title: "lemon pepper",
        spicyLevel: 0,
      },

      {
        title: "garlic parm",
        spicyLevel: 0,
      },
    ],
  },
  {
    headingTitle: "desserts",
    content: [
      {
        title: "deep fried oreos",
        price: "$8",
      },
      {
        title: "cotton candy",
        price: "$6",
      },
      {
        title: "pazookie",
        price: "$8",
      },
    ],
  },
];

export const menuData2: MenuData[] = [
  {
    headingTitle: "salads",
    content: [
      {
        title: "house cobbs salad",
        description:
          "Iceberg lettuce's, red onions, sliced eggs, tomatoes, crispy bacon, avocado.",
        price: "$14",
      },
      {
        title: "skirt steak salad",
        price: "$18",
      },
      {
        title: "crispy chicken salad",
        price: "$18",
      },
      {
        title: "chicken ceasar salad",
        price: "$18",
      },
    ],
  },
  {
    headingTitle: "nachos n dips",
    content: [
      {
        title: "buffalo chicken nachos",
        description:
          "Shredded Buffalo chicken green onion, blue cheese crumbles bacon and blue cheese questo sauce.",
        price: "$16",
      },
      {
        title: "Spinach Artichoke Dip",
        description: "With sour cream & salsa",
        price: "$12",
      },
      {
        title: "stadium nachos",
        description:
          "Steak, onions jalopenos pico de Gallo, sour cream, guacamole, cheese sauce.",
        price: "$16",
      },
      {
        title: "buffalo chicken dip",
        description:
          "Shredded chicken, green onions, cream cheese, and buffalo sauce.",
        price: "$12",
      },
    ],
  },
  {
    headingTitle: "sauces",
    outerBorder: true,
    inline: true,
    content: [
      {
        title: "stadium sauce",
      },
      {
        title: "homemade ranch",
      },
      {
        title: "bbq",
      },
      {
        title: "blue cheese",
      },
      {
        title: "cheese sauce",
      },
    ],
  },
  {
    headingTitle: "burgers",
    content: [
      {
        title: "hot & heavy",
        description: "Jalopenos, onions, bacon, pepper jack cheese.",
        price: "$18",
      },
      {
        title: "cowboy",
        description: "Onion rings, bbq sauce, bacon, American cheese.",
        price: "$18",
      },
      {
        title: "tuo's wanna smash quickie",
        description:
          "Lettuce, tomatoes, grilled onions, garlic, American cheese.",
        price: "$14",
      },
      {
        title: "*ALL BURGERS CAN BE MADE INTO A VEGAN OPTION",
        price: "$17",
      },
    ],
  },
  {
    headingTitle: "pizza",
    outerBorder: true,
    content: [
      {
        title: "pepperoni pizza",
        price: "$18",
      },
      {
        title: "lobster pizza",
        description:
          "Lobster sauce, chopped shrimp, tarragon, fresh garlic and three cheese all on light homemade crust.",
        price: "$18",
      },
    ],
  },
  {
    headingTitle: "plant based",
    content: [
      {
        title: "vegan lions made crab cake",
        price: "$13",
      },
      {
        title: "lions mane mushrooms",
        description: "Old Bay Aioli",
        price: "$15",
      },
      {
        title: "veggie cheese steak",
        description:
          "Mushrooms, onions, bell peppers, mayo, and provolence cheese.",
        price: "$13",
      },
      {
        title: "veggie burgers",
        description: "Hot and heavy Cowgirl All American Happy",
        price: "$19",
      },
      {
        title: "big que",
        description:
          "Veggie burger, lettuce, tomato, grilled onion, french fries on the burger.",
        price: "$19",
      },
    ],
  },
  {
    headingTitle: "floats and milkshakes",
    outerBorder: true,
    content: [
      {
        title: "root beer float",
        price: "$8",
      },
      {
        title: "orange cream float",
        price: "$8",
      },
      {
        title: "cinnamon toast crunch",
        price: "$8",
      },
      {
        title: "fruity pebble milkshake",
        price: "$8",
      },
      {
        title: "strawberry milkshake",
        price: "$8",
      },
    ],
  },
];
