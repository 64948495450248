import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../Store";

const useScrollTop = () => {
  const { currentRoute } = useSelector((state: RootState) => state.Routes);

  useEffect(() => window.scrollTo({ top: 0 }), [currentRoute]);
};

export default useScrollTop;
