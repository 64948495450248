export enum RoutePattern {
  HOME = "home",
  ABOUT = "about",
  PRIVATE_EVENT = "private-event",
  PRESS = "press",
  EVENTS = "event-calendar",
  SPORTS = "sports-calendar",
  EMAIL_SIGNUP = "email-signup",
  HOURS_AND_LOCATION = "hours-&-location",
  CONTACT = "contact",
  MUSIC = "music",
  MENU = "menus",
}
