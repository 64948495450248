import { ShowcaseSliderDataTypes } from "../../components/ShowcaseSlider/types";
import { RoutePattern } from "../../routes/RoutePattern";
import IMG_1 from "../../assets/images/1.jpg";
import IMG_2 from "../../assets/images/2.jpg";
import IMG_3 from "../../assets/images/3.jpg";
// import IMG_4 from "../../assets/images/4.jpg";
import IMG_4 from "../../assets/images/Downtown PHX.png";
import PATTERN_IMG from "../../assets/images/pattern.png";
import ShowcaseSlider from "../../components/ShowcaseSlider/ShowcaseSlider";
import Newsletter from "../../components/Newsletter/Newsletter";
import { useGlobalSelector } from "../../features/useSelector";

const data: ShowcaseSliderDataTypes[] = [
  {
    img: IMG_1,
  },
  {
    img: IMG_2,
  },
  {
    img: IMG_3,
  },
];

export default function HoursAndLocation() {
  const { navbarHeight } = useGlobalSelector();

  return (
    <div
      id={RoutePattern.HOURS_AND_LOCATION}
      className={`relative flex flex-col h-full md:min-h-screen`}
    >
      {/* <ShowcaseSlider data={data} title="hours & location" /> */}
      <div style={{ marginTop: navbarHeight }} />
      <div
        className={`flex flex-col-reverse py-[7vh] px-5 lg:flex-row md:px-[5vw] md:py-[5vw]`}
      >
        <div className="w-full">
          <img src={IMG_4} className="w-full h-full" />
        </div>
        <div className="w-full px-[2vw] text-center m-auto h-full pb-[4vh] lg:pb-0 lg:w-3/4 ">
          <div className="outer-border-1 mx-auto flex">
            <div className="outer-border-2 flex justify-center items-center flex-col w-full">
              <h3 className="heading-3">hours & location</h3>
              <p>
                <a
                  className="underline"
                  href="https://www.google.com/maps/search/50+W+Jefferson+St.+%23280+Phoenix,+AZ+85003?entry=gmail&source=g"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  50 W Jefferson St. #280
                </a>
                <br />
                <a
                  className="underline"
                  href="https://www.google.com/maps/search/50+W+Jefferson+St.+%23280+Phoenix,+AZ+85003?entry=gmail&source=g"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Phoenix, AZ 85003
                </a>
                <br />
                Phone No. (Coming Soon)
                <br />
                <a className="underline" href="mailto:contact@stadiumphx.com">
                  contact@stadiumphx.com
                </a>
              </p>
              <br />
              <span className="uppercase font-bold mb-[2vh]">
                hours of operation
              </span>
              <p>
                <b>Monday - Thursday</b>
                <br />
                3:00 PM - 12:00 AM
              </p>
              <br />
              <p>
                <b>Thursday - Friday</b>
                <br />
                4:00 PM - 2:00 AM
              </p>
              <br />
              <p>
                <b>Friday</b>
                <br />
                3:00 PM - 2:00 AM
              </p>
              <br />
              <p>
                <b>Saturday</b>
                <br />
                3:00 PM - 2:00 AM
              </p>
              <br />
              <p>
                <b>Sunday</b>
                <br />
                3:00 PM - 12:00 AM
              </p>
              <span className="italic mt-[2vh]">
                *Hours of operation are subject to change during sports seasons
                to accommodate early games.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
