import { menuData, menuData2 } from "./data";
import MenuItems from "./MenuItems";
import ShowcaseSlider from "../../components/ShowcaseSlider/ShowcaseSlider";
import BG_IMG from "../../assets/images/menu-bg-combined.png";
import LOGO_IMG from "../../assets/images/Stadium Emblem.png";
import { ShowcaseSliderDataTypes } from "../../components/ShowcaseSlider/types";
// import { data } from "../Events/Events";
import IMG_1 from "../../assets/images/slider/Menu 1-min.png";
import IMG_2 from "../../assets/images/slider/Menu 2-min.png";
import IMG_3 from "../../assets/images/slider/Menu 3-min.png";
import IMG_4 from "../../assets/images/slider/Menu 4-min.png";
import IMG_5 from "../../assets/images/slider/Menu 5-min.png";

const data: ShowcaseSliderDataTypes[] = [
  {
    img: IMG_1,
  },
  {
    img: IMG_2,
  },
  {
    img: IMG_3,
  },
  {
    img: IMG_4,
  },
  {
    img: IMG_5,
  },
];

export default function Menu() {
  return (
    <div id="menu" className="relative flex flex-col h-full md:min-h-screen">
      <div className="flex justify-center items-center fixed top-0 left-0 z-0 w-full h-screen">
        <img
          src={BG_IMG}
          className="w-full h-full object-cover object-center"
        />
      </div>
      <ShowcaseSlider data={data} title="menus" arrows={false} />
      <div className="relative flex flex-col lg:flex-row gap-x-[4vw] py-[7vh] px-[5vw] md:py-[5vh]">
        <MenuItems data={menuData} />
        <MenuItems data={menuData2} />
      </div>
    </div>
  );
}
