import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, useField } from "formik";
import { InputHTMLAttributes } from "react";

type InputFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  name: string;
  placeholder?: string;
  color?: string;
};

const InputField = ({
  label,
  placeholder,
  color = "dark",
  ...props
}: InputFieldProps) => {
  const [field, { error }] = useField(props);
  return (
    <div className={`flex flex-col py-[1vh] w-full text-${color}`}>
      {label && <label className="capitalize">{label}</label>}
      <input
        className={`w-full border-[.2vh] border-${color} text-${color} block py-[1vh] px-[1.2vw] bg-transparent placeholder:text-gray-500 outline-none focus:bg-primary`}
        style={{ border: !!error ? "1px solid rgb(239 68 68)" : "" }}
        id={field.name}
        placeholder={placeholder}
        {...field}
        {...props}
      />
      {!!error && (
        <span className="text-red-500 px-2 mt-2 text-sm">
          <FontAwesomeIcon icon={faWarning} className="mr-2" />
          {error}
        </span>
      )}
    </div>
  );
};

export default InputField;
