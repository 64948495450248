import { ShowcaseSliderDataTypes } from "../../components/ShowcaseSlider/types";
import { RoutePattern } from "../../routes/RoutePattern";
import IMG_1 from "../../assets/images/1.jpg";
import IMG_2 from "../../assets/images/2.jpg";
import IMG_3 from "../../assets/images/3.jpg";
// import IMG_4 from "../../assets/images/4.jpg";
import IMG_4 from "../../assets/images/4.jpg";
import PATTERN_IMG from "../../assets/images/pattern.png";
import ShowcaseSlider from "../../components/ShowcaseSlider/ShowcaseSlider";
import Newsletter from "../../components/Newsletter/Newsletter";
import { useGlobalSelector } from "../../features/useSelector";

export const data: ShowcaseSliderDataTypes[] = [
  {
    img: IMG_1,
  },
  {
    img: IMG_2,
  },
  {
    img: IMG_3,
  },
];

export default function EmailSignup() {
  const { navbarHeight } = useGlobalSelector();

  return (
    <div
      id={RoutePattern.EMAIL_SIGNUP}
      className="relative flex flex-col h-full"
    >
      {/* <ShowcaseSlider data={data} title="email signup" /> */}
      <div className="bg-black" style={{ height: navbarHeight }} />
      <div className="text-center lg:flex-row min-h-screen flex justify-center items-center">
        {/* <h3 className="heading-2 text-center">Coming Soon...</h3> */}
        <Newsletter />
      </div>
    </div>
  );
}
