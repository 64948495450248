import { dispatch } from "../../Store";
import {
  setNavbarHeight,
  setPopupActive,
  setPresavePopup,
  setRequiredPopup,
} from "../../features/Global/Global.slice";

export default class GlobalService {
  setPopupActive(value: boolean) {
    dispatch(setPopupActive(value));
  }

  setPresavePopup(payload: boolean) {
    dispatch(setPresavePopup(payload));
  }

  setRequiredPopup(payload: boolean) {
    dispatch(setRequiredPopup(payload));
  }

  setNavbarHeight(payload: string) {
    dispatch(setNavbarHeight(payload));
  }
}
