import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import { Arrow, SlideItem } from "./styled.components";
import { ShowcaseSliderDataTypes } from "./types";
import { CriOS, iOS } from "../../util";

const Container = styled(motion.div)``;

interface MerchSliderProps {
  data: ShowcaseSliderDataTypes[];
  title: string;
  arrows?: boolean;
}

export default function ShowcaseSlider({
  data,
  title,
  arrows = true,
}: MerchSliderProps) {
  const [selectedIdx, setSelectedIdx] = useState<number>(0);

  const ArrowPrev = (props: any) => {
    const { onClick } = props;
    return (
      <Arrow type="button" left="2%" className="from-lef" onClick={onClick}>
        <FontAwesomeIcon icon={faAngleLeft as any} />
      </Arrow>
    );
  };

  const ArrowNext = (props: any) => {
    const { onClick } = props;

    return (
      <Arrow type="button" onClick={onClick} right="2%" className="from-righ">
        <FontAwesomeIcon icon={faAngleRight as any} />
      </Arrow>
    );
  };

  const items = data.map((item, idx) => {
    return (
      <div
        key={idx}
        className="text-white my-auto text-center relative w-full h-full"
      >
        <img
          src={item.img}
          alt="ffm image"
          className="w-full h-full object-cover object-center"
        />
      </div>
    );
  });

  // ${ CriOS ? "h-[88vh]" : iOS ? "h-[91vh]" : "h-[93vh]" }
  return (
    <Container
      className={`
      relative w-full overflow-visible h-auto md:h-screen`}
    >
      <Slider
        dots={false}
        nextArrow={<ArrowNext />}
        prevArrow={<ArrowPrev />}
        autoplay
        pauseOnHover
        arrows={arrows}
        slidesPerRow={1}
        slidesToShow={1}
      >
        {items}
      </Slider>
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black bg-opacity-50 w-full h-full flex">
        <span className="text-[3vh] lg:text-[7vh] m-auto font-bold uppercase">
          {title}
        </span>
      </div>
    </Container>
  );
}
