import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { data } from "./data";
import styled from "styled-components";
import Audiomack from "../Icons/audiomack";

const SocialLink = styled.a`
  :not(:last-child) {
    margin-right: 0.8rem;
  }
`;

interface SocialIconProps {
  pandoraIconWidth?: string;
  iconWidth?: string;
}

export default function SocialIcons({
  iconWidth,
  pandoraIconWidth,
}: SocialIconProps) {
  return (
    <ul className="flex flex-row justify-center gap-x-[1vh] lg:justify-start">
      {data.map((item, idx) => (
        <SocialLink
          key={idx}
          href={item.link}
          rel="noreferrer"
          target="_blank"
          className="text-[2.5vh] bg-dark text-white text-center w-[2.5vh] h-[2.5vh] md:text-[2vh] md:w-[3vw] md:h-[3vw] flex justify-center items-center"
        >
          {item.link.includes("audiomack") ? (
            <Audiomack
              color="#ffffff"
              // className="shadow-black"
              style={{ filter: "drop-shadow(2px 2px 2px black)" }}
            />
          ) : (
            <FontAwesomeIcon
              icon={item.icon as any}
              // className="shadow-black"
              // style={{ filter: "drop-shadow(2px 2px 2px black)" }}
            />
          )}
        </SocialLink>
      ))}
    </ul>
  );
}
