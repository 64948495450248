const text = `
<br/>
<br/>
<span class="text-[110%]">
Arizona's Ultimate Downtown SportsBar Experience
</span>
<br/>
<br/>
Stadium Sports Bar is the ultimate sports destination located in the heart of downtown 
Phoenix. With a prime location, state-of-the-art facility, and a lively atmosphere, it's the 
go-to spot for sports enthusiasts. Featuring multiple large screens, high-definition 
televisions, and an immersive audiovisual experience, Stadium Sports Bar ensures that 
every guest has a front-row seat to all the action. Whether you're a die-hard fan or 
simply looking for a fun night out, Stadium Sports Bar offers a friendly staff, a wide 
selection of beverages, and a diverse menu of delicious dishes, making it the perfect 
place to gather with friends and enjoy the excitement of sports in the downtown Phoenix
CityScape area.
`;

export default function About() {
  return (
    <div
      id="about"
      className="bg-black px-[5vw] pb-[10vh] pt-[15vh] font-secondary"
    >
      <div
        className="text-center text-[7vw] md:text-[1.7vw]"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  );
}
