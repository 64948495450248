import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, useField } from "formik";
import { InputHTMLAttributes } from "react";

type InputFieldProps = InputHTMLAttributes<HTMLTextAreaElement> & {
  label?: string;
  name: string;
  placeholder?: string;
  color?: string;
};

const InputTextArea = ({
  label,
  placeholder,
  color = "dark",
  ...props
}: InputFieldProps) => {
  const [field, { error }] = useField(props);
  return (
    <div className="flex flex-col border-primary py-[1vh] w-full">
      {label && <label className="capitalize">{label}</label>}
      <textarea
        className={`border-[.1vh] border-${color} text-${color} block py-[1.5vh] px-4 bg-transparent placeholder:text-gray-500 outline-none`}
        id={field.name}
        placeholder={placeholder}
        rows={5}
        {...field}
        {...props}
      />
      {!!error && (
        <span className="text-red-500 px-2 mt-2 text-sm">
          <FontAwesomeIcon icon={faWarning} className="mr-2" />
          {error}
        </span>
      )}
    </div>
  );
};

export default InputTextArea;
