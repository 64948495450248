import { Field, Form, Formik, useField } from "formik";
import useEmailSubscribe, {
  error,
  success,
} from "../../hooks/useEmailSubscribe";
import InputField from "../Form/InputField";
import useSheetSubscribe from "../../hooks/useSheetSubscribe";

const Newsletter = () => {
  const { submitForm } = useSheetSubscribe();

  return (
    <div className="wrapper w-full bg-dark text-white">
      <h3 className="heading-2 text-white text-center">stay in touch</h3>
      <div className="w-full lg:w-3/4 mx-auto mt-[4vh]">
        <Formik
          initialValues={{
            email: "",
            firstName: "",
            lastName: "",
            endpoint:
              "https://script.google.com/macros/s/AKfycbx7Nma8kCJPCw7roxLAkuwA13ZPM3_5XHLK7NIlDfpr9EabioBhjK0ZEUoTzrXEa1V1dQ/exec",
          }}
          onSubmit={async (values, { resetForm }) => {
            try {
              const submitted = await submitForm(values);
              console.log(values);

              if (submitted) {
                success({ titleText: "Your info has been submitted" });
                resetForm();
              }
            } catch (err) {
              console.error(err);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form className="flex flex-col justify-center items-center">
              <div className="w-full flex flex-row gap-x-[2vh]">
                <InputField
                  color="white"
                  type="text"
                  name="firstName"
                  label="first name"
                />
                <InputField
                  color="white"
                  type="text"
                  name="lastName"
                  label="last name"
                />
              </div>
              <div className="w-full">
                <InputField
                  color="white"
                  type="email"
                  name="email"
                  label="email"
                />
              </div>

              <button className={`btn-secondary mt-[5vh]`} type="submit">
                {isSubmitting ? "submitting" : "subscribe"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Newsletter;
