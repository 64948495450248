import Newsletter from "../Newsletter/Newsletter";
import SocialIcons from "../SocialIcons/SocialIcons";
import LOGO_IMG from "../../assets/images/black-logo.png";

export default function Footer() {
  // footer
  return (
    <div id="footer" className="relative h-full w-full z-10 bg-white text-dark">
      {/* <Newsletter /> */}
      {/* <div className="divider" /> */}
      <div className="flex flex-col justify-center items-center text-center py-[4vh] px-[2vw] md:px-[5vw]">
        <img className="w-[3/4] md:w-[25%]" src={LOGO_IMG} alt="logo" />
        <span className="text-center block my-[2vh]">
          All Rights Reserved {new Date().getFullYear()}
        </span>
        <SocialIcons />
        {/* <div>
          <a href="#" target="_blank" rel="noreferrer">
            Terms and Conditions
          </a>
        </div> */}
      </div>
    </div>
  );
}
