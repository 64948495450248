import { RoutePattern } from "../../routes/RoutePattern";

interface RouteTypes {
  name: string;
  to?: string;
  smooth: boolean;
  isAnchor?: boolean;
  isEmail?: boolean;
}

const formatText = (str: string) => {
  return str.split("-").join(" ");
};

export const data: RouteTypes[] = [
  // {
  //   name: RoutePattern.HOME,
  //   to: "/",
  //   smooth: false,
  // },
  {
    name: RoutePattern.MENU,
    to: "/" + RoutePattern.MENU,
    smooth: false,
  },
  {
    name: formatText(RoutePattern.ABOUT),
    to: "/" + RoutePattern.ABOUT,
    smooth: false,
  },
  {
    name: formatText(RoutePattern.HOURS_AND_LOCATION),
    to: "/" + RoutePattern.HOURS_AND_LOCATION,
    smooth: false,
  },
  {
    name: formatText(RoutePattern.EVENTS),
    to: "/" + RoutePattern.EVENTS,
    smooth: false,
  },
  {
    name: formatText(RoutePattern.SPORTS),
    to: "/" + RoutePattern.SPORTS,
    smooth: false,
  },
  {
    name: formatText(RoutePattern.PRIVATE_EVENT),
    to: "/" + RoutePattern.PRIVATE_EVENT,
    smooth: false,
  },
  {
    name: RoutePattern.PRESS,
    to: "/" + RoutePattern.PRESS,
    smooth: false,
  },
  {
    name: formatText(RoutePattern.EMAIL_SIGNUP),
    to: "/" + RoutePattern.EMAIL_SIGNUP,
    smooth: false,
  },
];
