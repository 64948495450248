import { ShowcaseSliderDataTypes } from "../../components/ShowcaseSlider/types";
import { RoutePattern } from "../../routes/RoutePattern";
import IMG_1 from "../../assets/images/1.jpg";
import IMG_2 from "../../assets/images/2.jpg";
import IMG_3 from "../../assets/images/3.jpg";
// import IMG_4 from "../../assets/images/4.jpg";
import IMG_4 from "../../assets/images/4.jpg";
import PATTERN_IMG from "../../assets/images/pattern.png";
import ShowcaseSlider from "../../components/ShowcaseSlider/ShowcaseSlider";
import Newsletter from "../../components/Newsletter/Newsletter";

export const data: ShowcaseSliderDataTypes[] = [
  {
    img: IMG_1,
  },
  {
    img: IMG_2,
  },
  {
    img: IMG_3,
  },
];

export default function Press() {
  return (
    <div
      id={RoutePattern.PRESS}
      className="relative flex flex-col justify-center items-center h-full min-h-screen"
    >
      {/* <ShowcaseSlider data={data} title="press" /> */}
      <div className="text-center py-[7vh] px-5 lg:flex-row md:px-[5vw] md:py-[5vw]">
        <h3 className="heading-2 text-center">Coming Soon...</h3>
      </div>
    </div>
  );
}
