import { faPepperHot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuData } from "./data";

interface MenuItemsProps {
  data: MenuData[];
}

export default function MenuItems({ data }: MenuItemsProps) {
  return (
    <div className="flex flex-col gap-x-[2vh] gap-y-[10vh] w-full">
      {data.map((item, idx) => (
        <div
          key={idx}
          className={`${item.outerBorder && "outer-border-1"}  mx-auto flex w-full`}
        >
          <div
            className={`${item.outerBorder && "outer-border-2"} flex justify-center items-center flex-col w-full`}
          >
            <h3 className="heading-3">{item.headingTitle}</h3>
            <div
              className={`${item.inline ? "grid grid-cols-2 gap-y-[2vh]" : "flex flex-col gap-y-[7vh]"} mt-[4vh] w-full px-[2vw]`}
            >
              {item.content.map((content, contentId) => (
                <div
                  key={contentId}
                  className="flex flex-row justify-between w-full"
                >
                  <div className="w-3/4">
                    <h4
                      className={`heading-4 ${!content.price && !content.description && !content.spicyLevel ? "whitespace-nowrap" : ""}`}
                    >
                      {content.title}
                    </h4>
                    <p className="font-primary">{content.description}</p>
                  </div>
                  {content.price && (
                    <div>
                      <span className="bg-primary p-[1vh] text-dark font-bold">
                        {content.price}
                      </span>
                    </div>
                  )}
                  {!content.spicyLevel ? null : (
                    <div className="flex flex-row">
                      {Array.from(Array(content.spicyLevel)).map(() => (
                        <span className="text-primary">
                          <FontAwesomeIcon icon={faPepperHot} />
                        </span>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
