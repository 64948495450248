import { ShowcaseSliderDataTypes } from "../../components/ShowcaseSlider/types";
import { RoutePattern } from "../../routes/RoutePattern";
import IMG_1 from "../../assets/images/1.jpg";
import IMG_2 from "../../assets/images/2.jpg";
import IMG_3 from "../../assets/images/3.jpg";
// import IMG_4 from "../../assets/images/4.jpg";
import IMG_4 from "../../assets/images/4.jpg";
import PATTERN_IMG from "../../assets/images/pattern.png";
import ShowcaseSlider from "../../components/ShowcaseSlider/ShowcaseSlider";
import Newsletter from "../../components/Newsletter/Newsletter";
import { Form, Formik } from "formik";
import InputField from "../../components/Form/InputField";
import InputTextArea from "../../components/Form/InputTextarea";
import useSheetSubscribe, {
  error,
  success,
} from "../../hooks/useSheetSubscribe";
import { useGlobalSelector } from "../../features/useSelector";

export const data: ShowcaseSliderDataTypes[] = [
  {
    img: IMG_1,
  },
  {
    img: IMG_2,
  },
  {
    img: IMG_3,
  },
];

export default function PrivateEvent() {
  const { submitForm } = useSheetSubscribe();
  const { navbarHeight } = useGlobalSelector();

  return (
    <div
      id={RoutePattern.PRIVATE_EVENT}
      className="relative flex flex-col h-full text-[5vw] md:text-[1.5vw] md:min-h-screen"
    >
      {/* <ShowcaseSlider data={data} title="private event" /> */}
      <div style={{ marginTop: navbarHeight }} />
      <div className="flex flex-col py-[7vh] px-5 md:px-[5vw] md:py-[5vw]">
        <div className="w-full md:w-[70%] mx-auto ">
          <p className="text-center">
            We love hosting events, so bring your party to the stadium for a
            great experience. For inquiries, numbers, and more, please fill out
            the inquiry form below and we will be in touch within 24 business
            hours.
          </p>
          <span className="mt-[3vh] block text-center">
            <span className="block">PHONE# (Coming Soon)</span>
            <a className="underline block" href="mailto:contact@stadiumphx.com">
              contact@stadiumphx.com
            </a>
          </span>
        </div>
        <div className="p-[4vh] rounded-[.2vh] w-full mx-auto mt-[4vh] text-white">
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              phoneNumber: "",
              occasion: "",
              eventDate: "",
              startTime: "",
              endTime: "",
              numberOfPeople: "",
              additionalInfo: "",
              aboutUs: "",
              endpoint:
                "https://script.google.com/macros/s/AKfycbzFAG79cXs4LeduLb4lws0-v-LpBhN-s_D2HkH-BynOqJzN_5SplWWjtbhzbym54qZ3Cg/exec",
            }}
            onSubmit={async (values, { resetForm }) => {
              try {
                const submitted = await submitForm(values);
                console.log(values);

                if (submitted) {
                  success({ titleText: "Your party info has been submitted" });
                  resetForm();
                }
              } catch (err) {
                error({ titleText: "Error" });
                console.error(err);
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form className="flex flex-col justify-center items-center text-white">
                <div className="flex flex-col justify-center items-center w-full">
                  <h3 className="heading-3 text-white text-center">
                    YOUR CONTACT INFORMATION
                  </h3>
                  <div className="w-full flex flex-row gap-x-[2vh]">
                    <InputField
                      color="white"
                      type="text"
                      name="firstName"
                      label="first name*"
                      required
                    />
                    <InputField
                      color="white"
                      type="text"
                      name="lastName"
                      label="last name*"
                      required
                    />
                  </div>
                  <div className="w-full flex flex-row gap-x-[2vh]">
                    <InputField
                      color="white"
                      type="email"
                      name="email"
                      label="email*"
                      required
                    />
                    <InputField
                      color="white"
                      type="text"
                      name="phoneNumber"
                      label="phone number*"
                      required
                    />
                  </div>
                </div>
                <div className="flex flex-col justify-center items-center w-full mt-[4vh]">
                  <h3 className="heading-3 text-center text-white">
                    YOUR EVENT DETAILS
                  </h3>
                  <div className="w-full flex flex-row gap-x-[2vh]">
                    <InputField
                      color="white"
                      type="text"
                      name="occasion"
                      label="what is your occasion?*"
                      required
                    />
                  </div>
                  <div className="w-full flex flex-col md:flex-row gap-x-[2vh]">
                    <InputField
                      color="white"
                      type="date"
                      name="eventDate"
                      label="event date*"
                      required
                    />
                    <InputField
                      color="white"
                      type="time"
                      name="startTime"
                      label="start time*"
                      required
                    />
                    <InputField
                      color="white"
                      type="time"
                      name="endTime"
                      label="end time*"
                      required
                    />
                    <InputField
                      color="white"
                      type="number"
                      name="numberOfPeople"
                      label="number of people*"
                      required
                    />
                  </div>
                  <div className="w-full flex flex-row gap-x-[2vh]">
                    <InputTextArea
                      color="white"
                      name="additionalInfo"
                      label="Any additional information?"
                    />
                  </div>
                  <div className="w-full flex flex-row gap-x-[2vh]">
                    <InputTextArea
                      color="white"
                      name="aboutUs"
                      label="How did you hear about us?"
                    />
                  </div>
                </div>

                <button className={`btn-secondary mt-[5vh]`} type="submit">
                  {isSubmitting ? "submitting..." : "submit"}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
